import { VuexModule, Module } from 'vuex-module-decorators'
import { config } from '@/config'

@Module({ namespaced: true })
class App  extends VuexModule {
    appName: string = 'Promotion Online'
	appVersion: string = config.version
    menuLists: any[] = [
		{
			action: 'mdi-calendar-text-outline',
			active: false,
			title: 'Promotion Online',
		},
	]
}

export default App 
