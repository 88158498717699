import Vue from "vue"
import Vuex from "vuex"
import app from "./modules/app"
import authentication from "./modules/authentication"
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
})

export default new Vuex.Store({
    modules: {
        app,
        authentication,
    },
    plugins: [vuexLocal.plugin],
})  