

  import * as MSAL from '@azure/msal-browser'
  import * as CryptoJS from 'crypto-js';
  import { AuthenticateApi } from "@/api"
import axios from 'axios'

export default({
    data() {
        return {
          response: {
            data:{
               TokenEmail : '',
            }
          }
        }
    },
 
    async mounted() {
      debugger
      this.testEmail = await this.GetAutentication()
    },
    methods: {
 
        async GetAutentication()
        {
            this.TokenEmail = this.$route.query.TokenEmail
            console.log("Token : " + this.TokenEmail)
            this.email = this.$store.state.authentication.auth.userCode
            debugger
 
            var decrypted = CryptoJS.AES.decrypt("U2FsdGVkX1+8gPCKbCjcAph8Kh9LcFnDEHu7Rp3xjjAvDrLhpSdE5oGxiDRwgMpW", "Promotion");
            var decrtp = decrypted.toString(CryptoJS.enc.Utf8)
  
            console.log("decrypt : " + decrtp)
            debugger
            if (this.email == decrtp) {

                const accounts: MSAL.AccountInfo[] = AuthenticateApi.msalInstance.getAllAccounts()
                console.log("AuthenEmail  : ")
                console.log(accounts[0])
                debugger
                if (accounts.length > 0) {
                    this.TokenEmail = accounts[0].username

                } else {
                    this.TokenEmail = ""
                }
              
            } else {
                this.TokenEmail = ""
            }

          //  await axios.get('http://localhost:8080/authenEmail').then(TokenEmail => {
			    //   	console.log(TokenEmail);
			//});
           // const autenObj = await AuthenticateApi.AutenSystem(auten)


                  this.axios.get('http://webcode.me').then((response) => {
            console.log(response.data)
          })

        },
 

    }
})
