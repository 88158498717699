import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true })
class Authentication extends VuexModule {

    auth = {
        username: "",
        imageProfile: "",
        email: "",
        userid: "",
        userCode: "",
        firstName: "",
        lastName: "",
        firstNameth: "",
        lastNameth: "",
        allProjectFlag: "",
        roleCode: null,
        roleName: null,
        deptCode: "",
        
    } as any

    @Mutation
    public SET_AUTHINFO (auth: any): void {
        this.auth = {
            username: auth.username,
            imageProfile: auth.imageProfile,
            email: auth.email,
            userid: auth.userid,
            userCode: auth.userCode,
            firstName: auth.firstName,
            lastName: auth.lastName,
            firstNameth: auth.firstNameth,
            lastNameth: auth.lastNameth,
            allProjectFlag: auth.allProjectFlag,
            roleCode: auth.roleCode,
            roleName: auth.roleName,
            deptCode: auth.deptCode,
            
        }
    }

    @Action
    AUTHINFO(auth: string) {
        this.context.commit("SET_AUTHINFO", auth)
    }

   
}

export default Authentication 