
import 'reflect-metadata'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import * as MSAL from '@azure/msal-browser'
import { config } from '@/config'
import { AuthenticateApi, GraphMicrosoftApi } from "@/api"
import * as CryptoJS from 'crypto-js';
import { LoginInsertPromtoion } from '../api/LoginPromotion';

const Auth = namespace('authentication')

@Component
export default class Login extends Vue { 
    loading = false
    errorLogin = false
    appVersion = config.version
    apiVersion = ""
   
    LogOutObj = {
        email : ""
    }
 
    
    @Auth.Action
    private AUTHINFO!: (auth: any) => Promise<any>

    async btnLogin () {
        this.loading = true
        localStorage.clear()
        console.log("Login : ")
        await AuthenticateApi.LoginAD()
        try {
            const accounts: MSAL.AccountInfo[] = AuthenticateApi.msalInstance.getAllAccounts()
            console.log("Account : ")
            var hashEmail : String  = ""
            console.log(accounts)
            debugger
            if (accounts.length > 0) {
                if (accounts[0].name) {
                    var email = ""
                    var today = new Date()
                    email = accounts[0].username
                    var today = new Date()
                    var now_date = (today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate());
                    var now_time = (today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()).toString()
                    var dateTimes = now_date + 'T' + now_time; 
  
                    hashEmail = CryptoJS.SHA256(email + config.redirectpromotion + dateTimes ).toString();
                    //const imagePath = await GraphMicrosoftApi.GetImageProfile();
                    var   LoginObj = {
                            email : email,
                            name : accounts[0].username,
                            hashKey : hashEmail,
                            active : 1,
                            createdBy : accounts[0].username
                    }

                    console.log(LoginObj);
                    await LoginInsertPromtoion(LoginObj);
                    console.log(LoginObj)

                    this.AUTHINFO({ 
                        username: accounts[0].name,
                        imageProfile: "",
                        userid: "",
                        userCode: accounts[0].username,
                        firstName: "",
                        lastName: "",
                        firstNameth: "",
                        lastNameth: "",
                        allProjectFlag: "",
                        email:"",
                        roleCode: "",
                        roleName: "",
                        deptCode:"",

                    })
                    
                }
            } 
          this.loading = false
          window.location.href =  config.redirectpromotion + '/Default.aspx?Token='  + hashEmail ;
           
        } catch (error)  
        { 
            localStorage.setItem('isAuthPromotion', '')
            this.errorLogin = true
            console.log(" catch : " + error)
            this.loading = false
        }
    }
}
