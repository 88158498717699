
import 'reflect-metadata'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { AuthenticateApi, HomeApi } from "@/api"
import { config } from '@/config'

const AppInfo = namespace('app')
const Auth = namespace('authentication')

@Component
export default class App extends Vue { 
    drawer = true
	appVersion = config.version
	apiVersion = ""

	@AppInfo.State("appName") appName!: string
	@AppInfo.State("menuLists") items!: any[]
 	 
	@Auth.State('auth') auth!: any

	async mounted () {
		this.activeListMenu()
		//const version = await HomeApi.Version()
        //this.apiVersion = version
	}

	activeListMenu () {
		const path = this.$route.path
		this.items.forEach(item => {
			item.items.forEach((itemSubItem: any) => {
				if (itemSubItem.path === path) {
					if (itemSubItem.tab === 'Friends Get Friends') {
						this.items[0].active = true
						this.items[1].active = false
					}
					if (itemSubItem.tab === 'report') {
						this.items[0].active = false
						this.items[1].active = true
					}
				}
			})
		})
	}

	async btnLogout () {
		await AuthenticateApi.LogOutAd()
		localStorage.clear()
		this.$router.push('/login')
	}

}

