import axios from 'axios'
import { config } from '../config'
import { AuthenticateApi } from "@/api"

export const HttpRequest = () => {
	const instance = axios.create({
		baseURL: config.api,
		timeout: 300000,
	})

	const logId = Math.floor(Math.random() * 10000)

	instance.interceptors.request.use(async (request: any) => {
		console.log(`${logId} - [LOG API] REQUEST URL:`, `${request.baseURL}${request.url}`)
		console.log(`${logId} - [LOG API] REQUEST DATA:`, request.data ?? "")

		// await RecheckHttpRequest401()
		// let token: any = localStorage.getItem("token")
		const token = await AuthenticateApi.GetAcquireToken()
		request.headers['Authorization'] = `Bearer ${token}`

		return request
	})

	instance.interceptors.response.use(function (response) {
		console.log(`${logId} - [LOG API] RESPONSE STATUS CODE: ${response.status}`)
		console.log(`${logId} - [LOG API] RESPONSE DATA:`, response.data)
		return response
	}, function (error) {
		if (error.response.status === 401) {
			localStorage.removeItem("token")
			localStorage.removeItem("isAuth")
		}
		return Promise.reject(error)
	})

	return instance
} 

export const HttpGraphMicrosoftRequest = () => {
	const instance = axios.create({
		baseURL: config.graphMeEndpoint,
		timeout: 300000,
	})

	const logId = Math.floor(Math.random() * 10000)

	instance.interceptors.request.use(async (request: any) => {
		console.log(`${logId} - [LOG API GRAPHMICROSOFT] REQUEST URL:`, `${request.baseURL}${request.url}`)
		console.log(`${logId} - [LOG API GRAPHMICROSOFT] REQUEST DATA:`, request.data ?? "")

		// await RecheckHttpGraphMicrosoftRequest401()
		// let token: any = localStorage.getItem("TOKEN_GRAPHMICROSOFT")
		const token = await AuthenticateApi.GetAcquireTokenRead()
		request.headers['Authorization'] = `Bearer ${token}`

		return request
	})

	instance.interceptors.response.use(function (response) {
		console.log(`${logId} - [LOG API GRAPHMICROSOFT] RESPONSE STATUS CODE: ${response.status}`)
		console.log(`${logId} - [LOG API GRAPHMICROSOFT] RESPONSE DATA:`, response.data)
		return response
	}, function (error) {
		if (error.response.status === 401) {
			localStorage.removeItem("TOKEN_GRAPHMICROSOFT")
			throw new Error
		}
		return Promise.reject(error)
	})

	return instance
} 

export const HttpSaleUlityRequest = () => {
	const instance = axios.create({
		baseURL: config.endpointurllogin,
		timeout: 300000,
	})

	const logId = Math.floor(Math.random() * 10000)

	instance.interceptors.request.use(async (request: any) => {
		console.log(`${logId} - [LOG API GRAPHMICROSOFT] REQUEST URL:`, `${request.baseURL}${request.url}`)
		console.log(`${logId} - [LOG API GRAPHMICROSOFT] REQUEST DATA:`, request.data ?? "")
		return request
	})

	instance.interceptors.response.use(function (response) {
		console.log(`${logId} - [LOG API GRAPHMICROSOFT] RESPONSE STATUS CODE: ${response.status}`)
		console.log(`${logId} - [LOG API GRAPHMICROSOFT] RESPONSE DATA:`, response.data)
		return response
	}, function (error) {
		if (error.response.status === 401) {
			throw new Error
		}
		return Promise.reject(error)
	})

	return instance
} 


// const RecheckHttpRequest401 = async () => {
// 	try {
// 		await axios.get(`${config.api}/users/auth`, {
//             headers: { 
// 				'Authorization': `Bearer ${localStorage.getItem("token")}`}
//         })
// 	} catch 
// 	{
// 		await AuthenticateApi.GetAcquireToken()
// 	}
// }

const RecheckHttpGraphMicrosoftRequest401 = async () => {
	try {
		await axios.get(`${config.graphMeEndpoint}/v1.0/me/photo/$value`, {
            headers: { 
				'Authorization': `Bearer ${localStorage.getItem("TOKEN_GRAPHMICROSOFT")}`}
        })
	} catch 
	{
		await AuthenticateApi.GetAcquireTokenRead()
	}
}