import axios from 'axios'
import { config } from '../config';
import { HttpSaleUlityRequest } from './HttpRequest';

export interface IResponsePromtoion {
    message: string
    responseData:string
    error:any
}

export const LoginInsertPromtoion = async (requestObject: any) => {
    const data = JSON.stringify(requestObject);
    try {
        const response = await HttpSaleUlityRequest().post('/salesutils/api/ManageToken/insert', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (response.data.message === 'OK') {
            return response.data.responseData
        }
    } catch (error) {
        return "Faild"
    }
}

export const LogOutPromtoion = async (requestObject: any) => {
    const data = JSON.stringify(requestObject);
    try {
        const response = await axios.post(config.endpointurllogin + '/salesutils/api/ManageToken/logout', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (response.data.message === 'OK') {
            return response.data.responseData
        }
    } catch (error) {
        return "Faild"
    }
}

export const InsertAgent = async (requestObject: any) => {
    const data = JSON.stringify(requestObject);
    try {

        const response = await axios.post('https://stg-api.sansiri.net/salesutils/api/agent/insert', data, {
        //const response = await axios.post('https://stg-api.sansiri.net/salesutils/api/agent/insert', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (response.data.message === 'OK') {
            return response.data.responseData
        }
    } catch (error) {
        return "Faild"
    }
}

export const HttpSalesUlityRequest = () => {
	const instance = axios.create({
		baseURL: config.endpointurllogin,
		timeout: 300000,
	})

}
