export const config = {
    version: "v 1.7.0",
    environment: process.env.VUE_APP_ENVIRONMENT ?? "Development",
    api: process.env.VUE_APP_ENDPOINT ?? "https://promotiononline.sansiri.net/login",
    azureAd: {
        clientId: process.env.VUE_APP_AZURE_AD_CLIENT_ID ?? '4000d1df-d6d3-47af-ac75-16fec812b8eb',
        authority: process.env.VUE_APP_AZURE_AD_AUTHORITY ?? 'https://login.microsoftonline.com/875bdd0a-688b-41d2-96b7-454d280043aa',
        scopes: [process.env.VUE_APP_AZURE_AD_SCOPES ?? "api://4000d1df-d6d3-47af-ac75-16fec812b8eb/read", "User.Read.All"]
    },
    instrumentationKey: process.env.VUE_APP_INSIGHTS_KEY ?? "29db856b-9fb0-4372-90ea-f85d850280bc",
    graphMeEndpoint: process.env.VUE_APP_GRAPH_MICROSOFT ?? 'https://graph.microsoft.com',
    endpointurllogin : process.env.ENDPOINT_URL_LOGIN ?? "https://api.sansiri.net",
    redirectpromotion : process.env.REDIRECT_PROMOTION ?? "https://sansiriapp3.sansiri.com/EMS"
}
